import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';

class Start extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>Admin - Start</title>
                    <meta name="robots" content="noindex, nofollow" />
                </Helmet>
                <h1 className="title">Modifier la page:</h1>
                <table className="table is-fullwidth is-striped is-narrow ">
                    <thead>
                        <tr>
                            <th>Page</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                Homepage
                            </td>
                            <td>
                                <div className="field is-grouped">
                                    <p className="control">
                                        <Link to="/homepage">
                                            <button className="button is-link">
                                                Modifier
                                        </button>
                                        </Link>
                                    </p>
                                    <p className="control">
                                        <a href={'https://www.adella-avocat.fr/'} className="button" target="_blank" rel="noreferrer noopener">
                                            Voir la page live
                                        </a>
                                    </p>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Articles
                            </td>
                            <td>
                                <div className="field is-grouped">
                                    <p className="control">
                                        <Link to="/articles">
                                            <button className="button is-link">
                                                Modifier
                                        </button>
                                        </Link>
                                    </p>
                                    <p className="control">
                                        <a href={'https://www.adella-avocat.fr/publications/'} className="button" target="_blank" rel="noreferrer noopener">
                                            Voir la page live
                                        </a>
                                    </p>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Honoraires
                            </td>
                            <td>
                                <div className="field is-grouped">
                                    <p className="control">
                                        <Link to="/prices">
                                            <button className="button is-link">
                                                Modifier
                                        </button>
                                        </Link>
                                    </p>
                                    <p className="control">
                                        <a href={'https://www.adella-avocat.fr/honoraires/'} className="button" target="_blank" rel="noreferrer noopener">
                                            Voir la page live
                                        </a>
                                    </p>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Confidentialité
                            </td>
                            <td>
                                <div className="field is-grouped">
                                    <p className="control">
                                        <Link to="/privacy">
                                            <button className="button is-link">
                                                Modifier
                                        </button>
                                        </Link>
                                    </p>
                                    <p className="control">
                                        <a href={'https://www.adella-avocat.fr/confidentialite/'} className="button" target="_blank" rel="noreferrer noopener">
                                            Voir la page live
                                        </a>
                                    </p>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Error
                            </td>
                            <td>
                                <div className="field is-grouped">
                                    <p className="control">
                                        <Link to="/notfound">
                                            <button className="button is-link">
                                                Modifier
                                        </button>
                                        </Link>
                                    </p>
                                    <p className="control">
                                        <a href={'https://www.adella-avocat.fr/xxxxx/'} className="button" target="_blank" rel="noreferrer noopener">
                                            Voir la page live
                                        </a>
                                    </p>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </React.Fragment>
        )
    }
}

export default Start;