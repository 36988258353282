import React, { Component } from "react";
import { firebaseAuth } from "../config/firebase";
import { withRouter } from "react-router-dom";
import { store } from "../config/firebase";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
    };
    this.signOut = this.signOut.bind(this);
    this.openPage = this.openPage.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  makeid(length) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }
  toggleMenu() {
    let currentMenu = this.state.menu;
    this.setState({ menu: !currentMenu });
  }

  handleChange() {
    store
      .collection("push")
      .doc("bump")
      .update({ value: this.makeid(50) });
  }

  openPage(key) {
    this.props.history.push(key);
    window.scrollTo(0, 0);
  }

  signOut() {
    this.setState({ eLoading: true }, function () {
      this.LogOut();
    });
  }

  LogOut() {
    return firebaseAuth().signOut();
  }

  render() {
    return (
      <React.Fragment>
        <nav className="navbar" role="navigation" aria-label="main navigation">
          <div className="navbar-brand">
            <a className="navbar-item" href="/">
              <h1 className="title">Adella Avocat - CMS</h1>
            </a>

            <a
              role="button"
              className={
                this.state.menu ? "navbar-burger is-active" : "navbar-burger"
              }
              aria-label="menu"
              aria-expanded="false"
              data-target="navbarBasicExample"
              onClick={() => this.toggleMenu()}
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </a>
          </div>

          <div
            id="navbarBasicExample"
            className={
              this.state.menu ? "navbar-menu is-active" : "navbar-menu"
            }
          >
            <div className="navbar-start"></div>

            <div className="navbar-end">
              <div className="navbar-item">
                <div className="buttons">
                  <button
                    className="button is-primary"
                    onClick={() => this.openPage("/")}
                  >
                    Home
                  </button>
                  <button
                    className={
                      this.state.eLoading
                        ? "button is-warning is-loading "
                        : "button is-warning"
                    }
                    onClick={() => this.handleChange()}
                  >
                    Publier
                  </button>
                  <a
                    className={
                      this.state.eLoading
                        ? "button is-success is-loading "
                        : "button is-success"
                    }
                    href="/images"
                  >
                    Images
                  </a>
                  <button
                    className={
                      this.state.eLoading
                        ? "button is-danger is-loading "
                        : "button is-danger"
                    }
                    onClick={() => this.signOut()}
                  >
                    Me déconnecter
                  </button>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </React.Fragment>
    );
  }
}

export default withRouter(Header);
