import React, { Component } from 'react';
import Loadable from 'react-loadable';
import Start from './pages/Start';
import Login from './components/Login';
import Header from './components/Header';
import Loader from './components/Loader';
import { BrowserRouter } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';
import { firebaseAuth } from './config/firebase';
import './App.css';

import fontawesome from '@fortawesome/fontawesome'
//import faStar from '@fortawesome/fontawesome-free-solid/faStar';
import faLock from '@fortawesome/fontawesome-free-solid/faLock';
import faCheck from '@fortawesome/fontawesome-free-solid/faCheck';
import faEnvelope from '@fortawesome/fontawesome-free-solid/faEnvelope';

fontawesome.library.add(/*faStar*,*/ faLock, faCheck, faEnvelope);

function MyLoadingComponent(props) {
  if (props.pastDelay) {
    return <Loader />;
  }
  else if (props.error) {
    return <div className="title">Une erreur s'est produite lors du chargement de la page</div>;
  }
  else {
    return null;
  }
};



const AsyncArticles = Loadable({
  loader: () => import("./pages/Articles" /* webpackChunkName: "equipe" */),
  loading: MyLoadingComponent,
  delay: 100,
  timeout: 10000
});

const AsyncArticleAdd = Loadable({
  loader: () => import("./pages/ArticleAdd" /* webpackChunkName: "articleadd" */),
  loading: MyLoadingComponent,
  delay: 100,
  timeout: 10000
});

const AsyncArticleModify = Loadable({
  loader: () => import("./pages/ArticleModify" /* webpackChunkName: "articlemodify" */),
  loading: MyLoadingComponent,
  delay: 100,
  timeout: 10000
});


const AsyncPrices = Loadable({
  loader: () => import("./pages/Prices" /* webpackChunkName: "prices" */),
  loading: MyLoadingComponent,
  delay: 100,
  timeout: 10000
});

const AsyncPrivacy = Loadable({
  loader: () => import("./pages/Privacy" /* webpackChunkName: "privacy" */),
  loading: MyLoadingComponent,
  delay: 100,
  timeout: 10000
});


const AsyncHome = Loadable({
  loader: () => import("./pages/Home" /* webpackChunkName: "home" */),
  loading: MyLoadingComponent,
  delay: 100,
  timeout: 10000
});

const AsyncError = Loadable({
  loader: () => import("./pages/Errors" /* webpackChunkName: "error" */),
  loading: MyLoadingComponent,
  delay: 100,
  timeout: 10000
});

const AsyncNotFound = Loadable({
  loader: () => import("./pages/NotFound" /* webpackChunkName: "notFound" */),
  loading: MyLoadingComponent,
  delay: 100,
  timeout: 10000
});

const AsyncImages = Loadable({
  loader: () => import("./pages/Images" /* webpackChunkName: "images" */),
  loading: MyLoadingComponent,
  delay: 100,
  timeout: 10000
});

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      authed: false,
      loading: true
    }
  }

  componentDidMount() {
    firebaseAuth().onAuthStateChanged(function (user) {
      if (user) {
        this.setState({
          authed: true,
          loading: false
        })
      } else {
        this.setState({
          authed: false,
          loading: false
        })
      }
    }.bind(this));
  }

  render() {
    return (
      <React.Fragment>
        {this.state.loading === true ?
          <Loader /> :
          <section className="section">
          <div className="container">
            {this.state.authed ?
              <BrowserRouter>
                <Header />
                <Switch>
                  <Route path='/' exact component={Start} />
                  <Route path='/homepage' exact component={AsyncHome} />
                  <Route path='/articles/ajouter' exact component={AsyncArticleAdd} />
                  <Route path='/articles/:article' exact component={AsyncArticleModify} />
                  <Route path='/articles' exact component={AsyncArticles} />
                  <Route path='/prices' exact component={AsyncPrices} />
                  <Route path='/privacy' exact component={AsyncPrivacy} />
                  <Route path='/images' exact component={AsyncImages} />
                  <Route path='/notfound' exact component={AsyncError} />
                  <Route component={AsyncNotFound} />
                </Switch>
              </BrowserRouter> : <Login />}
          </div></section>}
      </React.Fragment>
    );
  }
}

export default App;
