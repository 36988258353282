var config = {
  apiKey: "AIzaSyCb6fJFfBluBtUDEtLqE8jYauYllqB52gM",
  authDomain: "adella-avocat-front.firebaseapp.com",
  databaseURL: "https://adella-avocat-front-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "adella-avocat-front",
  storageBucket: "adella-avocat-front.appspot.com",
  messagingSenderId: "337784253802",
  appId: "1:337784253802:web:cff26f10fb17dec623c913"
};

export default config;