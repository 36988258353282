import React, { Component } from 'react';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import { firebaseAuth } from '../config/firebase';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            eLoading: false,
            mailCheck: false,
            passwordCheck: false
        }
        this.signIn = this.signIn.bind(this);
    }

    signIn(key) {
        this.setState({ eLoading: true }, function () {
            this.eMail();
        })
    }

    eMail(e) {
        firebaseAuth().signInWithEmailAndPassword(this.state.email, this.state.password)
            .catch((error) => {
                console.log(error)
            })
    };

    onChangeFormHandler(e) {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({ [name]: value },
            () => { this.validateField(name, value); });
    }

    validateField(fieldName, value) {
        let emailValid = this.state.emailCheck;
        let passwordValid = this.state.passwordCheck;

        switch (fieldName) {
            case 'email':
                var re = /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                emailValid = re.test(value);
                this.setState({
                    mailCheck: emailValid
                })
                break;
            case 'password':
                passwordValid = value.length >= 8;
                this.setState({
                    passwordCheck: passwordValid
                })
                break;
            default:
                break;
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="columns has-text-centered">
                    <div className="column is-offset-4 is-4">
                        <button className="button is-primary is-fullwidth">
                            <span>Me connecter via mon adresse e-mail</span>
                        </button>
                    </div>
                </div>
                <div className="columns has-text-left">
                    <div className="column is-offset-4 is-4">
                        <div className="field">
                            <p className="control has-icons-left has-icons-right">
                                <input className="input" type="email" placeholder="E-mail" name="email" value={this.state.email} onChange={this.onChangeFormHandler.bind(this)} />
                                <span className="icon is-small is-left">
                                    <FontAwesomeIcon icon="envelope" />
                                </span>
                                {this.state.mailCheck ? <span className="icon is-small is-right">
                                    <FontAwesomeIcon icon="check" />
                                </span> : null}
                            </p>
                        </div>
                        <div className="field">
                            <p className="control has-icons-left has-icons-right">
                                <input className="input" type="password" name="password" placeholder="Mot de passe" value={this.state.password} onChange={this.onChangeFormHandler.bind(this)} />
                                <span className="icon is-small is-left">
                                    <FontAwesomeIcon icon="lock" />
                                </span>
                                {this.state.passwordCheck ? <span className="icon is-small is-right">
                                    <FontAwesomeIcon icon="check" />
                                </span> : null}
                            </p>
                        </div>
                        <div className="field is-grouped is-grouped-right">
                            <p className="control">
                                <button className={this.state.eLoading ? "button is-primary is-loading " : "button is-primary"} onClick={() => this.signIn('email')} disabled={!this.state.mailCheck || !this.state.passwordCheck}>
                                    Me connecter
                                </button>
                            </p>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Login;