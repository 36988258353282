import config from './config';
import firebase from 'firebase/app';
require("firebase/storage");
require("firebase/firestore");
require('firebase/database')
require("firebase/auth");

firebase.initializeApp(config);
export const storage = firebase.storage();
export const firebaseAuth = firebase.auth;
export const store = firebase.firestore();
export const pagesRef = store.collection('pages');
export const articlesRef = store.collection('pages').doc('articles').collection('article');
export const db = firebase.database(); 